<template>
  <div class="onb-wrapper">
    <v-card
      class="mx-auto"
    >
      <v-card-title class="d-flex align-center justify-center py-7">
        <h2
          class="text-2xl font-weight-semibold"
        >
          Bienvenue sur Jimbo
        </h2>
      </v-card-title>

      <!-- content -->
      <v-card-text class="d-flex flex-column justify-center">
        <v-dialog
          v-model="dialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              color="primary"
              block
              v-on="on"
            >
              Je rejoins une entreprise
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Code d'invitation
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              Pour rejoindre une entreprise éxistante veuillez saisir le code d'invitation présent dans l'e-mail que vous avez reçu.
              <v-text-field
                v-model="invitationCode"
                outlined
                :error="inviteError || invitationCode.length > 16"
                :success="inviteSuccess"
                :loading="inviteLoading"
                label="Code d'invitation"
                placeholder="df1b42cedd83ca91"
                class="mt-5"
                @input="checkCode"
              ></v-text-field>
            </v-card-text>
            <v-card-text v-if="inviteSuccess">
              Vous allez rejoindre :
              <v-chip
                color="primary"
                outlined
              >
                {{ companyName }}
              </v-chip>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="companyName.length === 0"
                color="primary"
                text
                @click="$router.push({name:'dashboard'})"
              >
                C'est parti
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <span class="ma-5 text-center">ou</span>

        <v-dialog
          v-model="newCompanyDialog"
          max-width="1000px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              color="success"
              block
              v-on="on"
            >
              Je créé une entreprise
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Je créé mon entreprise
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              Afin de paramétrer votre entreprise, nous avons besoin de quelques informations :
            </v-card-text>
            <v-card-text>
              <OnboardingCreateCompanyStepper
                @newCompanyCancel="newCompanyDialog = false"
                @companyChange="(payload) => company = payload"
                @stepChange="(payload) => step = payload"
              />
            </v-card-text>
            <v-card-text>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                :disabled="step < 3"
                @click="createCompany"
              >
                C'est parti
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import OnboardingCreateCompanyStepper from './OnboardingCreateCompanyStepper.vue'

export default {
  metaInfo: {
    title: 'Onboarding',
    titleTemplate: '%s - Jimbo',
  },
  components: {
    OnboardingCreateCompanyStepper,
  },
  data: () => ({
    step: 0,
    dialog: false,
    newCompanyDialog: false,
    invitationCode: '',
    inviteLoading: false,
    inviteError: false,
    inviteSuccess: false,
    companyName: '',
    company: {
    },
  }),
  methods: {
    checkCode(e) {
      this.inviteError = false
      if (e.length === 16) {
        this.inviteLoading = true
        const invite = {
          inviteToken: this.invitationCode,
          // eslint-disable-next-line no-underscore-dangle
          user: this.$store.state.user._id,
        }
        this.$http.post(`${process.env.VUE_APP_API_URL}/company/acceptInvite`, invite)
          .then(res => {
            this.inviteLoading = false
            this.inviteSuccess = true
            this.companyName = res.data.company.name
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.inviteLoading = false
              this.inviteError = true
            }
            console.log(err.response)
          })
          .finally()
      }
    },
    createCompany() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/company/new`, this.company)
        .then(res => {
          if (res.status === 200) {
            this.$store.dispatch('loginWithCompany', { company: res.data.company._id })
              .catch(err => console.log(err))
              .finally()
            this.$store.commit('company', res.data.company)
            this.$router.push({ name: 'dashboard' })
          } else {
            throw new Error('uh oh')
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
  },
}
</script>

<style lang=scss>
@import '../../styles/onboarding.scss'
</style>
