<template>
  <div>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Type d'entreprise
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Informations de l'entreprise
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Apparence & Style
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-content step="1">
        <v-card>
          <v-card-text>
            <v-btn-toggle
              v-model="company.type"
              class="d-flex justify-space-around"
              tile
              color="primary"
              group
            >
              <v-btn value="of">
                Organisme de formation (OF)
              </v-btn>

              <v-btn value="ae">
                Freelance (Auto-entreprise)
              </v-btn>

              <v-btn value="entreprise">
                Entreprise
              </v-btn>

              <v-btn value="other">
                Autre
              </v-btn>
            </v-btn-toggle>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="step = 2"
            >
              Suivant
            </v-btn>

            <v-btn
              text
              @click="$emit('newCompanyCancel')"
            >
              Retour
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-items>
        <v-stepper-content step="2">
          <v-form ref="step1">
            <v-text-field
              v-model="company.name"
              label="Nom de l'entreprise"
              :rules="[v => !!v || 'Le nom de l\'entreprise est requis']"
            >
            </v-text-field>
            <v-text-field
              v-model="company.website"
              label="Site web"
            >
            </v-text-field>
            <v-text-field
              v-model="company.siret"
              label="Siret"
            >
            </v-text-field>

            <v-btn
              color="primary"
              @click="$refs.step1.validate() ? step =3 : ''"
            >
              Suivant
            </v-btn>

            <v-btn
              text
              @click="step = 1"
            >
              Annuler
            </v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card>
            <v-card-text>
              <v-item-group
                v-model="selectedColorScheme"
                mandatory
                @change="updateColorScheme"
              >
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(schema, index) in colorSchemes"
                      :key="index"
                      cols="12"
                      md="4"
                    >
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          :color="schema.color"
                          class="d-flex align-center"
                          dark
                          height="200"
                          @click="toggle"
                        >
                          <v-scroll-y-transition>
                            <div
                              v-if="active"
                              class="text-h2 flex-grow-1 text-center"
                            >
                              {{ schema.name }} <v-icon x-large>
                                {{ icons.mdiCheckCircle }}
                              </v-icon>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="step = 2"
              >
                Retour
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mdiCheckCircle } from '@mdi/js'

export default {
  data: () => ({
    step: 1,
    selectedColorScheme: 0,
    company: {
      type: 'of',
      colorScheme: 'violet',
      name: '',
      website: '',
      siret: '',
    },
    icons: {
      mdiCheckCircle,
    },
    colorSchemes: [
      {
        name: 'Violet',
        color: 'primary',
      },
      {
        name: 'Orange',
        color: '#FF9800',
      },
      {
        name: 'Dark',
        color: '#424242',
      },
    ],
  }),
  watch: {
    company: {
      handler(val) {
        this.$emit('companyChange', val)
      },
      deep: true,
    },
    step(val) {
      this.$emit('stepChange', val)
    },
  },
  methods: {
    updateColorScheme(e) {
      this.company.colorScheme = this.colorSchemes[e].name
    },
  },

}
</script>

<style>

</style>
